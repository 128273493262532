<template>
  <router-link :to="{ name: 'detalle-producto', params: { productId } }">
    <article class="media">
      <div class="media-left" v-if="productImage">
        <figure class="image is-64x64 thumbnailImage" :style="`background-image: url('${productImage}')`">
        </figure>
      </div>
      <div class="media-content">
        <div class="content">
          <p>
            <strong>{{productName}}</strong>
            <br>
            <small>{{formatMoney(price)}}</small>
            <br><br>
            Condición: {{productCondition === 'n' ? 'Nuevo' : 'Usado'}}
            <br>
            <span
              v-if="showStatus"><span class="tag"
              :class="[
                parseInt(productStatusId) === 3 ? 'is-success'
                : parseInt(productStatusId) === 2 ? 'is-link'
                : parseInt(productStatusId) === 4 ? 'is-danger'
                : 'is-warning'
              ]"
              >{{productStatus}}</span></span>
          </p>
        </div>
      </div>
    </article>
  </router-link>
</template>

<script>
export default {
  props: {
    productId: {
      type: Number
    },
    productName: {
      type: String
    },
    productImage: {
      type: String
    },
    productDesciption: {
      type: String
    },
    price: {
      type: Number
    },
    productCondition: {
      type: String
    },
    productStatus: {
      type: String
    },
    productStatusId: {
      type: Number
    },
    showStatus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatMoney (amount) {
      return parseInt(amount).toLocaleString('en', { style: 'currency', currency: 'USD' })
    }
  }
}
</script>

<style scoped>
  .productList a, .productList a:active, .productList a:focus, .productList a:visited {
    color: #000000;
    font-size:12px;
  }
  .media {
    padding: 5px;
  }
  .media:hover {
    background-color: #F0F0F0;
  }
  .content strong{
    color: var(--secondary-color);
    font-size: 12px;
  }
  .content {
    font-size: 11px;
  }
  .thumbnailImage {
    background-size: cover;
    background-position: center;
  }
</style>
